import React from 'react';

import { SoundProvider } from 'src/context/SoundContext';
import { ModalProvider } from 'src/context/ModalContext';

import { Layout } from './layout';
import { Header } from './header';
import { Footer } from './footer';

import { SoundSection } from '../sound';
import { TabRouter } from './tab-router';
import { TabRouterProvider } from '../../context/TabRouterContext';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Application: React.FC<Props> = () => {
  return (
    <ModalProvider>
      <TabRouterProvider>
        <SoundProvider>
          <Layout>
            <Header />
            <TabRouter />
            <Footer />
          </Layout>
        </SoundProvider>
      </TabRouterProvider>
    </ModalProvider>
  );
}