import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, CircularProgress, Tab, Tabs } from "@mui/material";
import classNames from 'classnames';

import { flatten, get, includes, isArray } from "lodash";
import { Maybe } from 'src/types';
import { AppleMusicSound, ContentType, SpotifySound, TwitterSound, YouTubeSound } from 'src/services/resources';
import { EMPTY_BLOCK, EMPTY_STRING } from 'src/utils/common';
import { TabPanel } from '../../shared/tab-panel';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  item: {
    fontSize: '10px',
    color: '#cccccc',
    lineBreak: 'anywhere',
    wordBreak: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
    fontWeight: 100,
  },
  itemLink: {
    color: '#cccccc',
    textDecoration: 'none',
  },
  appleFrame: {
    width: '100%',
    overflow: 'hidden',
    background: 'transparent',
  },
  tab: {
    backgroundColor: '#fff !important',
    fontFamily: 'Arial !important',
    letterSpacing: 0,
  },
  twitterSection: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  twitterItem: {
    width: '100%',
  },
  twitterLoader: {
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '20px',
  },
  hidden: {
    display: 'none',
  }
});

type Props = {
  type: ContentType | ContentType[];
  secretToken: string;
  code: string;
  title: string;
  id?: Maybe<string>;
  appleSound?: Maybe<AppleMusicSound>;
  spotifySound?: Maybe<SpotifySound>;
  youtubeSound?: Maybe<YouTubeSound>;
  twitterSound?: Maybe<TwitterSound>;
  twitterLoading?: Maybe<boolean>;
};

export const SoundItem: React.FC<Props> = ({
  type,
  code,
  secretToken,
  id,
  title,
  appleSound,
  spotifySound,
  youtubeSound,
  twitterSound,
  twitterLoading,
}) => {
  const classes = useStyles();

  const activeTabIndex = includes(flatten([type]), ContentType.SoundCloud)
    ? 0
    : includes(flatten([type]), ContentType.YouTube)
      ? 3
      : includes(flatten([type]), ContentType.Twitter)
      ? 4
      : 0;

  const [tabIndex, setTabIndex] = useState(activeTabIndex);

  const iframe = {
    [ContentType.SoundCloud]: <iframe
      width="100%"
      height="160"
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={`https://w.soundcloud.com/player/?url=https://api.soundcloud.com/tracks/${id}&secret_token=${secretToken}&color=#002e30&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
    />,
    [ContentType.YouTube]: <iframe
      height="315"
      style={{ width: '100%' }}
      src={`https://www.youtube.com/embed/${get(youtubeSound, 'token', EMPTY_STRING)}`}
      title={`${title}. Etc.`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />,
    [ContentType.AppleMusic]: <iframe
      allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
      frameBorder="0"
      height="175"
      className={classes.appleFrame}
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      src={`https://embed.music.apple.com/us/album/${code}/${get(appleSound, 'album.id', void 0)}?i=${get(appleSound, 'id', void 0)}`}
    />,
    [ContentType.Spotify]: <iframe
      style={{ borderRadius: '12px' }}
      src={`https://open.spotify.com/embed/track/${get(spotifySound, 'id', void 0)}?theme=1`}
      width="100%"
      height="152"
      frameBorder="0"
      allowFullScreen={false}
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />,
    [ContentType.Twitter]: (
      <div className={classNames([classes.twitterSection, twitterLoading ? classes.hidden : void 0])}>
        <TwitterTweetEmbed
          tweetId={get(twitterSound, 'id', EMPTY_STRING)}
        />
      </div>
    )
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const onTabIndexChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const currentType = isArray(type) ? type : flatten([type]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={onTabIndexChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label={`${title}`}
        >
          {
            includes(currentType, ContentType.SoundCloud)
              ? <Tab className={classes.tab} label="SoundCloud" {...a11yProps(0)} />
              : EMPTY_BLOCK
          }
          {
            includes(currentType, ContentType.Spotify)
              ? <Tab className={classes.tab} label="Spotify" {...a11yProps(1)} />
              : EMPTY_BLOCK
          }
          {
            includes(currentType, ContentType.AppleMusic)
              ? <Tab className={classes.tab} label="Apple Music" {...a11yProps(2)} />
              : EMPTY_BLOCK
          }
          {
            includes(currentType, ContentType.YouTube)
              ? <Tab className={classes.tab} label="YouTube" {...a11yProps(3)} />
              : EMPTY_BLOCK
          }
          {
            includes(currentType, ContentType.Twitter)
              ? <Tab className={classes.tab} label={twitterLoading ? `Twitter. Just a sec...` : `Twitter`} {...a11yProps(4)} />
              : EMPTY_BLOCK
          }
        </Tabs>
      </AppBar>
      <>
        {
          includes(currentType, ContentType.SoundCloud)
            ? <TabPanel value={tabIndex} index={0}>
                {iframe[ContentType.SoundCloud]}
              </TabPanel>
            : EMPTY_BLOCK
        }
        {
          includes(currentType, ContentType.Spotify)
            ? <TabPanel value={tabIndex} index={1}>
              {iframe[ContentType.Spotify]}
            </TabPanel>
            : EMPTY_BLOCK
        }
        {
          includes(currentType, ContentType.AppleMusic)
            ? <TabPanel value={tabIndex} index={2}>
                {iframe[ContentType.AppleMusic]}
              </TabPanel>
            : EMPTY_BLOCK
        }
        {
          includes(currentType, ContentType.YouTube)
            ? <TabPanel value={tabIndex} index={3}>
                {iframe[ContentType.YouTube]}
              </TabPanel>
            : EMPTY_BLOCK
        }
        {
          includes(currentType, ContentType.Twitter)
            ? <TabPanel value={tabIndex} index={4}>
                {iframe[ContentType.Twitter]}
              </TabPanel>
            : EMPTY_BLOCK
        }
      </>
    </div>
  );
}