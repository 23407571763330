import { v4 as uuidv4 } from 'uuid';

import { Maybe } from 'src/types';

export const AUTHOR = 'Etc.';

export type Uid = string;

export enum ContentType {
  SoundCloud = 'soundcloud',
  Spotify = 'spotify',
  AppleMusic = 'apple-music',
  YouTube = 'youtube',
  Twitter = 'twitter',
}

export type Sound = {
  type: ContentType | ContentType[],
  uid: Uid;
  title: string;
  secretToken: string;
  date: Date;
  code: string;
  id?: string;
  youtube?: Maybe<YouTubeSound>;
  apple?: Maybe<AppleMusicSound>;
  spotify?: Maybe<SpotifySound>;
  twitter?: Maybe<TwitterSound>;
}

export type TwitterSound = {
  id: string;
};

export type AppleMusicSound = {
  album: AppleMusicSoundAlbum;
  id: string;
};

export type YouTubeSound = {
  token: string;
};

export type SpotifySound = {
  id: string;
};

export type AppleMusicSoundAlbum = {
  code: string;
  id: string;
}

export const SOUND_LIST: Sound[] = [
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Mixdown :: Nowadays',
    id: '1263776287',
    secretToken: 's-fZKI2DD7HwR',
    uid: uuidv4(),
    date: new Date(2022, 4, 7),
    code: 'life-mixdown-nowadays',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Sample2 :: Initial',
    id: '1243492060',
    secretToken: 's-uAs3Tf30LxS',
    uid: uuidv4(),
    date: new Date(2022, 3, 3),
    code: 'life-sample2-initial',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Mixdown :: Alfa',
    id: '1247799907',
    secretToken: 's-LRlL0HxWY9Q',
    uid: uuidv4(),
    date: new Date(2022, 3, 10),
    code: 'life-mixdown-alfa',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: ContentType.YouTube,
    title: 'Life :: Neural :: Mogilev',
    secretToken: '',
    uid: uuidv4(),
    date: new Date(2022, 8, 30),
    code: 'life-neural-mogilev',
    youtube: {
      token: 'lOE5S-USSOo',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Neural :: Music Dance',
    secretToken: 's-KJ3yqGxbErl',
    id: '1381787551',
    uid: uuidv4(),
    date: new Date(2022, 10, 11),
    code: 'life-neural-music-dance',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Digital :: Whisper',
    secretToken: 's-KJ3yqGxbErl',
    id: '1386821170',
    uid: uuidv4(),
    date: new Date(2022, 10, 20),
    code: 'life-digital-whisper',
    apple: {
      album: {
        code: '',
        id: '1661473865',
      },
      id: '1661473866',
    },
    spotify: {
      id: '2jCdcaV1k1nxQrDvgDAGqQ',
    },
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Digital :: Track',
    secretToken: 's-KJ3yqGxbErl',
    id: '1386974758',
    uid: uuidv4(),
    date: new Date(2022, 10, 20),
    code: 'life-digital-track',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Digital :: Sample',
    secretToken: 's-KJ3yqGxbErl',
    id: '1391479786',
    uid: uuidv4(),
    date: new Date(2022, 10, 27),
    code: 'life-digital-sample',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473868',
    },
    spotify: {
      id: '5VX5KVH3tILBGnD7F9BRJh',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Mixer :: Sample',
    secretToken: 's-KJ3yqGxbErl',
    id: '1394020411',
    uid: uuidv4(),
    date: new Date(2022, 11, 1),
    code: 'life-mixer-sample',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473869',
    },
    spotify: {
      id: '5koXUJth3Tpt5x4GSGJbha',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Quick :: Sample',
    secretToken: 's-KJ3yqGxbErl',
    id: '1400011483',
    uid: uuidv4(),
    date: new Date(2022, 11, 10),
    code: 'life-quick-sample',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473870',
    },
    spotify: {
      id: '79lI9ut1GD6mGbpVhmAQ1p',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Quick :: Track',
    secretToken: 's-KJ3yqGxbErl',
    id: '1400051170',
    uid: uuidv4(),
    date: new Date(2022, 11, 10),
    code: 'life-quick-track',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473871',
    },
    spotify: {
      id: '01BLdhgUjEEL9PZR6dipup',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Neural :: Guitar',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1401864535',
    uid: uuidv4(),
    date: new Date(2022, 11, 13),
    code: 'life-neural-guitar',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473872',
    },
    spotify: {
      id: '5fT33fPHfL8RRym1659rHk',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Neural :: Joke',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1402516099',
    uid: uuidv4(),
    date: new Date(2022, 11, 14),
    code: 'life-neural-joke',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473873',
    },
    spotify: {
      id: '7bEQalrqboPq1Y81cNCt0I',
    },
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Neutral :: Saturday',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1404712471',
    uid: uuidv4(),
    date: new Date(2022, 11, 17),
    code: 'life-neutral-sunday',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473874',
    },
    spotify: {
      id: '4a8yKQEzyZzmQiCLunAcsI',
    },
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Neutral :: Sunday',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1405213147',
    uid: uuidv4(),
    date: new Date(2022, 11, 18),
    code: 'life-neutral-sunday',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Digital :: Bass',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1407535840',
    uid: uuidv4(),
    date: new Date(2022, 11, 21),
    code: 'life-digital-bass',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661473875',
    },
    spotify: {
      id: '7qxmUg5xFylTCtGHdj1ue5',
    },
  },
  {
    type: ContentType.SoundCloud,
    title: 'Life :: Change :: Initial',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1408114603',
    uid: uuidv4(),
    date: new Date(2022, 11, 22),
    code: 'life-change-initial',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.Spotify, ContentType.AppleMusic],
    title: 'Life :: Change :: Sample',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1408867345',
    uid: uuidv4(),
    date: new Date(2022, 11, 23),
    code: 'life-change-sample',
    apple: {
      album: {
        id: '1661473865',
        code: 'neutral-life',
      },
      id: '1661474086',
    },
    spotify: {
      id: '5oxZP3mH9tfIbBdXHaKBIp',
    },
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Digital :: Usual',
    secretToken: 's-X3L3wQ6k6Bd',
    id: '1412343649',
    uid: uuidv4(),
    date: new Date(2022, 11, 29),
    code: 'life-digital-usual',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.AppleMusic, ContentType.Spotify],
    title: 'Life :: Change :: New',
    secretToken: '',
    id: '1416231136',
    uid: uuidv4(),
    date: new Date(2023, 0, 4),
    code: 'life-change-new',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206963',
    },
    spotify: {
      id: '6Lyx7EmNQKsRjDX4gP4aF1',
    },
  },
  {
    type: [
      ContentType.YouTube,
      ContentType.AppleMusic,
      ContentType.SoundCloud,
    ],
    title: 'Life :: Neural :: Default',
    secretToken: '',
    id: '1413796174',
    uid: uuidv4(),
    date: new Date(2023, 0, 1),
    code: 'life-neural-default',
    youtube: {
      token: '1tWRzvcFDqA',
    },
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206962',
    },
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Neural :: New',
    secretToken: '1tWRzvcFDqA',
    id: '1416231136',
    uid: uuidv4(),
    date: new Date(2023, 0, 4),
    code: 'life-neural-new',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: General :: Tempo',
    secretToken: '1tWRzvcFDqA',
    id: '1421471098',
    uid: uuidv4(),
    date: new Date(2023, 0, 11),
    code: 'life-general-tempo',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206964',
    },
    spotify: {
      id: '7wmuMIwwExuTtQtkOParGP',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: General :: Intelligence',
    secretToken: '1tWRzvcFDqA',
    id: '1426889230',
    uid: uuidv4(),
    date: new Date(2023, 0, 18),
    code: 'life-general-intelligence',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206965',
    },
    spotify: {
      id: '4qPILEfItZeL336M54WSU9',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Change :: Step',
    secretToken: '1tWRzvcFDqA',
    id: '1434498403',
    uid: uuidv4(),
    date: new Date(2023, 0, 28),
    code: 'life-change-step',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206966',
    },
    spotify: {
      id: '4VK9VseNNRz0YhnH6R3F2M',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Change :: Sample Variant',
    secretToken: '1tWRzvcFDqA',
    id: '1435734307',
    uid: uuidv4(),
    date: new Date(2023, 0, 30),
    code: 'life-change-sample-variant',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206969',
    },
    spotify: {
      id: '3XvcmI5ouT1Ww6Bd8TBTyD',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Default :: Sample',
    secretToken: '',
    id: '1450229644',
    uid: uuidv4(),
    date: new Date(2023, 1, 18),
    code: 'life-defailt-sample',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206970',
    },
    spotify: {
      id: '420vaYovNJmPcq5CVqTk8w',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Social :: Neural',
    secretToken: '1tWRzvcFDqA',
    id: '1434997429',
    uid: uuidv4(),
    date: new Date(2023, 0, 29),
    code: 'life-social-neural',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206968',
    },
    spotify: {
      id: '7JfO6PJz7RLmqca0awHWQy',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Good :: Night',
    secretToken: '1tWRzvcFDqA',
    id: '1448533447',
    uid: uuidv4(),
    date: new Date(2023, 1, 19),
    code: 'life-good-night',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206973',
    },
    spotify: {
      id: '60rCznFcqos2Mtq1djtRNX',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Mixer :: Launchpad',
    secretToken: '1tWRzvcFDqA',
    id: '1452231733',
    uid: uuidv4(),
    date: new Date(2023, 1, 21),
    code: 'life-mixer-launchpad',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206971',
    },
    spotify: {
      id: '1mnMrwZ4VuCt94TmZ9fPJ6',
    },
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Sample :: Cleaning',
    secretToken: '1tWRzvcFDqA',
    id: '1464330505',
    uid: uuidv4(),
    date: new Date(2023, 2, 8),
    code: 'life-sample-cleaning',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206972',
    },
    spotify: {
      id: '6OrNT6aHPWYraWVdSc1ecL',
    },
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Sample :: Quick',
    secretToken: '1tWRzvcFDqA',
    id: '1465159609',
    uid: uuidv4(),
    date: new Date(2023, 2, 9),
    code: 'life-sample-quick',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Disco :: What',
    secretToken: 's-Na2JAlcvKRI',
    id: '1467160273',
    uid: uuidv4(),
    date: new Date(2023, 2, 14),
    code: 'disco-what',
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206974',
    },
    spotify: {
      id: '',
    },
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Disco :: Echo',
    secretToken: 's-WjyamG9eVuV',
    id: '1470557833',
    uid: uuidv4(),
    date: new Date(2023, 2, 16),
    code: 'life-disco-echo',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Sample :: Intro',
    secretToken: 's-WjyamG9eVuV',
    id: '1476113932',
    uid: uuidv4(),
    date: new Date(2023, 2, 23),
    code: 'life-sample-intro',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Natural :: Intro',
    secretToken: 's-WjyamG9eVuV',
    id: '1479785998',
    uid: uuidv4(),
    date: new Date(2023, 2, 28),
    code: 'life-natural-intro',
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.YouTube, ContentType.SoundCloud],
    title: 'Life :: Natural :: Alpha',
    secretToken: 's-dkRmq1JTurj',
    id: '1480418932',
    uid: uuidv4(),
    date: new Date(2023, 2, 29),
    code: 'life-natural-alpha',
    youtube: {
      token: 'ewcTvPbjvo0',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.YouTube, ContentType.SoundCloud],
    title: 'Life :: Natural :: Beta',
    secretToken: 's-nyRXxhSsM7T',
    id: '1480993543',
    uid: uuidv4(),
    date: new Date(2023, 2, 29),
    code: 'life-natural-beta',
    youtube: {
      token: '68PHwMc8Gm4',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.YouTube, ContentType.SoundCloud],
    title: 'Life :: Natural :: Gamma',
    secretToken: 's-7vQgYeyK01h',
    id: '1481274484',
    uid: uuidv4(),
    date: new Date(2023, 2, 29),
    code: 'life-natural-gamma',
    youtube: {
      token: '6Gt4VjR-xVg',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.AppleMusic, ContentType.Spotify, ContentType.SoundCloud],
    title: 'Life :: Neural :: Usual',
    secretToken: '',
    id: '1412343649',
    uid: uuidv4(),
    date: new Date(2022, 11, 29),
    code: 'life-neural-usual',
    youtube: void 0,
    apple: {
      album: {
        id: '1677206960',
        code: 'usual-neural',
      },
      id: '1677206961',
    },
    spotify: {
      id: '0yDE3iwrxGiqXiyVfn1VrW'
    },
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Neural :: Clear',
    secretToken: '',
    id: '1486648594',
    uid: uuidv4(),
    date: new Date(2023, 3, 6),
    code: 'life-neural-clear',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Neural :: New',
    secretToken: '',
    id: '1492802011',
    uid: uuidv4(),
    date: new Date(2023, 3, 14),
    code: 'life-neural-new',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.YouTube],
    title: 'Life :: Neural :: Feeling',
    secretToken: 's-At4prLaemzo',
    id: '1493386144',
    uid: uuidv4(),
    date: new Date(2023, 3, 15),
    code: 'life-neural-feeling',
    youtube: {
      token: 'pgMawfAvLMw',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.YouTube],
    title: 'Life :: Default',
    secretToken: '',
    id: '1494813874',
    uid: uuidv4(),
    date: new Date(2023, 3, 17),
    code: 'life-default',
    youtube: {
      token: '95LywMiFCHk',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Seldom :: Someone',
    secretToken: '',
    id: '1497731791',
    uid: uuidv4(),
    date: new Date(2023, 3, 21),
    code: 'life-seldom-someone',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Sample :: Weekend',
    secretToken: '',
    id: '1503882442',
    uid: uuidv4(),
    date: new Date(2023, 4, 1),
    code: 'life-sample-weekend',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Session :: Resetone',
    secretToken: '',
    id: '1507946485',
    uid: uuidv4(),
    date: new Date(2023, 4, 6),
    code: 'life-session-resetone',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Session :: Mystery',
    secretToken: '',
    id: '1513006624',
    uid: uuidv4(),
    date: new Date(2023, 4, 15),
    code: 'life-session-mystery',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Session :: Tempo',
    secretToken: '',
    id: '1519019023',
    uid: uuidv4(),
    date: new Date(2023, 4, 21),
    code: 'life-session-tempo',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Alpha',
    secretToken: 's-KdoCqI3DUo2',
    id: '1522280614',
    uid: uuidv4(),
    date: new Date(2023, 4, 25),
    code: 'composition-alpha',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Poetry :: English',
    secretToken: 's-DAQUzvVanxH',
    id: '1524162640',
    uid: uuidv4(),
    date: new Date(2023, 4, 27),
    code: 'life-poetry-english',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Beta',
    secretToken: 's-q0N0Z564jzz',
    id: '1524410578',
    uid: uuidv4(),
    date: new Date(2023, 4, 28),
    code: 'composition-beta',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Gamma',
    secretToken: 's-DYpdm1hUYU5',
    id: '1527547546',
    uid: uuidv4(),
    date: new Date(2023, 4, 31),
    code: 'composition-gamma',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Delta',
    secretToken: '',
    id: '1533089044',
    uid: uuidv4(),
    date: new Date(2023, 5, 7),
    code: 'composition-delta',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Epsilon',
    secretToken: 's-zi8zNEzJ5qt',
    id: '1539093382',
    uid: uuidv4(),
    date: new Date(2023, 5, 13),
    code: 'composition-epsilon',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Zeta',
    secretToken: '',
    id: '1539652327',
    uid: uuidv4(),
    date: new Date(2023, 5, 14),
    code: 'composition-zeta',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Eta',
    secretToken: '',
    id: '1540186771',
    uid: uuidv4(),
    date: new Date(2023, 5, 15),
    code: 'composition-eta',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Life :: Session :: Check',
    secretToken: '',
    id: '1543070140',
    uid: uuidv4(),
    date: new Date(2023, 5, 17),
    code: 'life-session-check',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.Twitter],
    code: 'meet-me-on-radio',
    title: 'Hey! Meet me on the radio!',
    uid: uuidv4(),
    date: new Date(2023, 5, 25, 21, 0),
    secretToken: '',
    id: '',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
    twitter: {
      id: '1671575214537289728'
    },
  },
  {
    type: [ContentType.Twitter],
    code: 'radio-show-record',
    title: 'Hey! The radio show record!',
    uid: uuidv4(),
    date: new Date(2023, 5, 26, 7, 5),
    secretToken: '',
    id: '',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
    twitter: {
      id: '1673346653527453704'
    },
  },
  {
    type: [ContentType.SoundCloud],
    title: 'Composition :: Alloy',
    secretToken: '',
    id: '1551422686',
    uid: uuidv4(),
    date: new Date(2023, 5, 27),
    code: 'composition-alloy',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud, ContentType.YouTube],
    title: `Life :: C'mon`,
    secretToken: '',
    id: '1554868183',
    uid: uuidv4(),
    date: new Date(2023, 6, 2),
    code: 'life-cmon',
    youtube: {
      token: '9ASYjPTl6es',
    },
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Composition :: C'mon :: Bit`,
    secretToken: '',
    id: '1565096353',
    uid: uuidv4(),
    date: new Date(2023, 6, 14),
    code: 'composition-cmon-bit',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Tyts`,
    secretToken: '',
    id: '1565311996',
    uid: uuidv4(),
    date: new Date(2023, 6, 15),
    code: 'life-session-tyts',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Composition :: Tyts`,
    secretToken: '',
    id: '1566435106',
    uid: uuidv4(),
    date: new Date(2023, 6, 16),
    code: 'composition-tyts',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Rela`,
    secretToken: '',
    id: '1566568327',
    uid: uuidv4(),
    date: new Date(2023, 6, 16),
    code: 'life-session-rela',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: GO`,
    secretToken: '',
    id: '1568745271',
    uid: uuidv4(),
    date: new Date(2023, 6, 19),
    code: 'life-session-go',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Switch`,
    secretToken: '',
    id: '1584993587',
    uid: uuidv4(),
    date: new Date(2023, 7, 7),
    code: 'life-session-switch',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Poem :: Comes`,
    secretToken: 's-6ebNAjgsMKA',
    id: '1590919375',
    uid: uuidv4(),
    date: new Date(2023, 7, 12),
    code: 'life-poem-comes',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Composition :: World`,
    secretToken: '',
    id: '1596064827',
    uid: uuidv4(),
    date: new Date(2023, 7, 18),
    code: 'composition-world',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Composition :: Wood`,
    secretToken: '',
    id: '1603684626',
    uid: uuidv4(),
    date: new Date(2023, 7, 29),
    code: 'composition-wood',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Period`,
    secretToken: 's-Ou4s8UsBM5t',
    id: '1615514715',
    uid: uuidv4(),
    date: new Date(2023, 8, 13),
    code: 'life-session-period',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Birds`,
    secretToken: '',
    id: '1621913355',
    uid: uuidv4(),
    date: new Date(2023, 8, 21),
    code: 'life-session-birds',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Digital`,
    secretToken: '',
    id: '1624232769',
    uid: uuidv4(),
    date: new Date(2023, 8, 24),
    code: 'life-session-digital',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Jazz`,
    secretToken: '',
    id: '1624232769',
    uid: uuidv4(),
    date: new Date(2023, 8, 25),
    code: 'life-session-jazz',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Techno`,
    secretToken: '',
    id: '1626046911',
    uid: uuidv4(),
    date: new Date(2023, 8, 26),
    code: 'life-session-techno',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Hip-hop`,
    secretToken: '',
    id: '1626857751',
    uid: uuidv4(),
    date: new Date(2023, 8, 27),
    code: 'life-session-hip-hop',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Rock`,
    secretToken: '',
    id: '1635163668',
    uid: uuidv4(),
    date: new Date(2023, 9, 8),
    code: 'life-session-rock',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
  {
    type: [ContentType.SoundCloud],
    title: `Life :: Session :: Reggaeton`,
    secretToken: '',
    id: '1663026207',
    uid: uuidv4(),
    date: new Date(2023, 10, 12),
    code: 'life-session-reggaeton',
    youtube: void 0,
    apple: void 0,
    spotify: void 0,
  },
];