import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { compact, eq, find, flatten, get, includes, isNull, isUndefined, map, orderBy, replace, size } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { AccessibilityNew, Apple, ExpandMore, Twitter, YouTube } from '@mui/icons-material';

import { Maybe, Nullable } from 'src/types';
import { SoundContext } from 'src/context/SoundContext';
import { ModalContext } from 'src/context/ModalContext';
import {
  AppleMusicSound,
  ContentType,
  Sound,
  SpotifySound,
  TwitterSound,
  Uid,
  YouTubeSound
} from 'src/services/resources';
import { EMPTY_ARRAY, EMPTY_BLOCK, EMPTY_STRING, HASH } from 'src/utils/common';
import { openUrl } from 'src/utils/window';

import { SoundContentSection } from './sound-content-section';

import { theme } from 'src/styles/theme';
import { color } from '../../styles/color';
import { localeDate, utcDateTime } from '../../utils/date';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: '105px',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '155px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '145px',
    },

  },
  accordion: {
    width: '100%',
  },
  accordionExpanded: {
    // margin: '0 auto !important',
  },
  accordionSummaryExpanded: {
    borderBottom: `1px solid ${theme.palette.primary.main} !important`,
  },
  actionList: {
    position: 'absolute',
    top: '5px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginLeft: '-10px',
      top: 0,
    },
  },
  dateSection: {
    height: '21px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  dateValue: {
    marginTop: '-3px !important',
  },
  emptySection: {
    textAlign: 'center',
    marginLeft: '10px !important',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  summaryContainer: {
    paddingLeft: '4px',
  },
  actionSpotify: {
    // width: '110px',
    // top: '10px',
  }
})

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const SoundSection: React.FC<Props> = () => {
  const classes = useStyles();

  const { list, isExpanded, expand } = useContext(SoundContext);
  const { setBody, handleOpen } = useContext(ModalContext);

  const accordionRef = useRef<Nullable<HTMLDivElement>[]>(EMPTY_ARRAY);

  function sortList(list: Sound[]) {
    return orderBy(list, [({ date }: Sound) => date], 'desc');
  }

  const emptySection = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'body2'} className={classes.emptySection}>
          Empty...
        </Typography>
      </Grid>
    </Grid>
  );

  /**
   * Composes the Apple Sound track link
   * @param code Sound code
   * @param appleSound
   */
  function composeAppleMusicSoundLink(code: string, appleSound: Maybe<AppleMusicSound>): Maybe<string> {
    return !isUndefined(appleSound)
      ? `https://music.apple.com/us/album/${code}/${appleSound.album.id}?i=${appleSound.id}`
      : void 0;
  }

  /**
   * Composes the Spotify track link
   * @param spotify Spotify Sound code
   */
  function composeSpotifySoundLink(spotify: Maybe<SpotifySound>): Maybe<string> {
    return !isUndefined(spotify)
      ? `https://open.spotify.com/track/${get(spotify, 'id', void 0)}?go=1`
      : void 0;
  }

  /**
   * Composes the YouTube track link
   * @param secretToken YouTube Sound code
   */
  function composeYouTubeSoundLink(secretToken: Maybe<string>): Maybe<string> {
    return !isUndefined(secretToken)
      ? `https://youtu.be/${secretToken}`
      : void 0;
  }

  /**
   * Composes the Twitter track link
   * @param id Twitter Sound id
   */
  function composeTwitterSoundLink(id: Maybe<string>): Maybe<string> {
    return !isUndefined(id)
      ? `https://twitter.com/etcsci/status/${id}?s=20`
      : void 0;
  }

  const actionList = {
    appleMusic: (code: string, appleSound: Maybe<AppleMusicSound>) => (
      !isUndefined(appleSound)
        ? <Tooltip
            title={"Apple Music Track"}
            arrow
          >
            <IconButton
              onClick={() => {
                const link = composeAppleMusicSoundLink(code, appleSound);
                if (!isUndefined(link)) {
                  openUrl(link);
                } else {
                  return void 0;
                }
              }}
              aria-label={code}
            >
              <Apple fontSize={'small'}/>
            </IconButton>
          </Tooltip>
        : EMPTY_BLOCK
    ),
    youtube: (type: ContentType | ContentType[], youtubeSound: Maybe<YouTubeSound>) => (
      (eq(type, ContentType.YouTube) || includes(type, ContentType.YouTube))
        ? <Tooltip
            title={"YouTube Track"}
            arrow
          >
            <IconButton
              onClick={() => {
                const link = composeYouTubeSoundLink(get(youtubeSound, 'token', EMPTY_STRING));
                if (!isUndefined(link)) {
                  openUrl(link);
                } else {
                  return void 0;
                }
              }}
              aria-label={get(youtubeSound, 'token', EMPTY_STRING)}
            >
              <YouTube fontSize={'small'}/>
            </IconButton>
          </Tooltip>
        : EMPTY_BLOCK
    ),
    twitter: (type: ContentType | ContentType[], twitterSound: Maybe<TwitterSound>) => (
      (eq(type, ContentType.Twitter) || includes(type, ContentType.Twitter))
        ? <Tooltip
            title={"Twitter Announce"}
            arrow
          >
            <IconButton
              onClick={() => {
                const link = composeTwitterSoundLink(get(twitterSound, 'id', EMPTY_STRING));
                if (!isUndefined(link)) {
                  openUrl(link);
                } else {
                  return void 0;
                }
              }}
                aria-label={get(twitterSound, 'id', EMPTY_STRING)}
            >
              <Twitter fontSize={'small'}/>
            </IconButton>
          </Tooltip>
        : EMPTY_BLOCK
    ),
    spotify: (spotify: Maybe<SpotifySound>) => (
      !isUndefined(spotify)
        ? <Tooltip
            title={"Spotify Track"}
            arrow
          >
            <div
              style={{ marginTop: '5px', marginLeft: '5px' }}
              className={classes.actionSpotify}
              onClick={() => {
                const link = composeSpotifySoundLink(spotify);
                if (!isUndefined(link)) {
                  openUrl(link);
                } else {
                  return void 0;
                }
              }}
              aria-label={get(spotify, 'id', void 0)}
            >
              <i className="fa-brands fa-spotify"></i>
            </div>
          </Tooltip>
        : EMPTY_BLOCK
    ),
  };

  function getHashUid(): Maybe<Uid> {
    const hash = window.location.hash;
    return !isUndefined(hash)
      ? get(find(list, ({ code }: Sound) => eq(code, replace(hash, HASH, EMPTY_STRING))), 'uid', void 0)
      : void 0;
  }

  useEffect(() => {
    const hashUid = getHashUid();
    if (!isUndefined(hashUid)) {
      const key = sortList(list)
        .findIndex(({ uid }: Sound) => eq(uid, hashUid));
      if (!isUndefined(key) && !isNull(key)) {
        const element = accordionRef.current[key];
        if (!isNull(element)) {
          const scrollTop = element.offsetTop;
          // window.scrollTo({
          //   top: scrollTop - 300,
          // });
        }
      }
    }
  }, []);

  const [twitterLoading, setTwitterLoading] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      {eq(size(list), 0) ? emptySection : EMPTY_BLOCK }
        {map(sortList(list), ({ uid, title, date, code, secretToken, id, type, apple, spotify, youtube, twitter }: Sound, key: number) => {
        const hashUid = getHashUid();
        const actionStack = compact([
          !isUndefined(apple)
            ? <>
              <div className={classes.actionList}>
                {actionList.appleMusic(code, apple)}
              </div>
            </>
            : void 0,
          !isUndefined(spotify)
            ? <>
              <div className={classes.actionList}>
                {actionList.spotify(spotify)}
              </div>
            </>
            : void 0,
          eq(type, ContentType.YouTube) || includes(type, ContentType.YouTube)
            ? <>
              <div className={classes.actionList}>
                {actionList.youtube(type, youtube)}
              </div>
            </>
            : void 0,
          eq(type, ContentType.Twitter) || includes(type, ContentType.Twitter)
            ? <>
              <div className={classes.actionList}>
                {actionList.twitter(type, twitter)}
              </div>
            </>
            : void 0
        ]);
        return (
          <Accordion
            disableGutters
            ref={(element: Nullable<HTMLDivElement>) => accordionRef.current[key] = element}
            defaultExpanded={!isUndefined(hashUid) && eq(uid, hashUid)}
            expanded={get(isExpanded, uid, false) || eq(hashUid, uid)}
            onChange={(event: React.SyntheticEvent, _: boolean) => {
              if (!eq(get(event, 'target.innerText', void 0), HASH)) {
                expand(uid);
                if (includes(flatten([type]), ContentType.Twitter)) {
                  setTwitterLoading(true);
                  setTimeout(() => {
                    setTwitterLoading(false);
                  }, 1500);
                }
              }
            }}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={!isUndefined(hashUid) && eq(uid, hashUid) ? <AccessibilityNew />: <ExpandMore />}
              id={uid}
            >
              <Grid
                container
                className={classes.summaryContainer}
              >
                <Grid item xs={12} sm={4}>
                  <div className={classes.title}>
                    <Typography sx={{ color: includes(flatten([type]), ContentType.Twitter) ? color.twitter : 'text.primary' }} variant={'h3'}>
                      {title}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Stack
                    style={{ marginRight: '20px', padding: '5px 0' }}
                    direction="row-reverse"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={5}

                  >
                    {actionStack}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dateSection}>
                  <Typography
                    sx={{ color: 'text.secondary' }}
                    variant={'body2'}
                    className={classes.dateValue}
                  >
                    {includes(flatten([type]), ContentType.Twitter) ? utcDateTime(date) : localeDate(date)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <SoundContentSection
                type={type}
                title={title}
                secretToken={secretToken}
                id={id}
                date={date}
                code={code}
                appleSound={apple}
                spotifySound={spotify}
                youtubeSound={youtube}
                twitterSound={twitter}
                twitterLoading={twitterLoading}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}