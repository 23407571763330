import { config } from 'src/config';

export function composeSoundLink(hash: string): string {
  return `${config.protocol}://www.${config.domain}#${hash}`;
}

/**
 * Twitter Sound Content
 * @param url
 */
export function composeTwitterContent(): string {
  return `Hey! Listen for Etc.sound new track!`;
}

export function utf8format(str: string): string {
  return encodeURIComponent(str);
}