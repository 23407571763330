import React from 'react';
import { styled } from '@mui/styles';
import { Container } from '@mui/material';

export const ContainerCustom = styled(Container)(() => {
  return {
    width: '100%',
    padding: '0 !important',
    margin: '0 !important',
  };
});