import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, IconButton,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

import { config } from 'src/config';
import { theme } from 'src/styles/theme';

import { Search } from './search';
import { EMPTY_NUMBER } from '../../../utils/common';
import { openUrl } from '../../../utils/window';
import QrCode from 'react-qr-code';
import { composeSoundLink } from '../../../utils/content';
import { Check } from '@mui/icons-material';
import { TabRouterContext } from '../../../context/TabRouterContext';
import { TabRouterItems } from '../../../services/resources/app.model';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    zIndex: '10',
    background: '#fff',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '40px',
  },
  logoImage: {
    height: '50px',
  },
  title: {
    paddingTop: '10px',
    '&:link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:visited': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '&:active': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  search: {
    paddingLeft: '20px',
    // paddingBottom: '20px',
    // background: theme.palette.primary.main,
    // background: `linear-gradient(180deg, rgba(0,46,48,1) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,1) 100%)`,
    // backgroundPosition: '5px 5px, 0 0',
    width: '100%',
  },
  contactBody: {},
  contactTitleText: {},
  contactTitle: {},
  contactActions: {},
  author: {},
  menuTab: {
    fontFamily: 'Arial !important',
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export const Header: React.FC<Props> = () => {
  const classes = useStyles();

  const { current, setCurrentItem } = useContext(TabRouterContext);

  const [topMenuItem, setTopMenuItem] = useState(EMPTY_NUMBER);
  const [bottomMenuItem, setBottomMenuItem] = useState(EMPTY_NUMBER);
  const [contactOpen, setContactOpen] = useState(false);

  const onTopMenuItemChange = (event: React.SyntheticEvent, newItem: number) => {
    // router
    setTopMenuItem(newItem)
  }

  const onBottomMenuItemChange = (event: React.SyntheticEvent, newItem: number) => {
    // router
    setBottomMenuItem(newItem)
  }

  const onContactClose = () => {
    setContactOpen(false);
  }

  useEffect(() => {
    if (bottomMenuItem === 1) {
      openUrl(config.soundCloudProfile);
    } else if (bottomMenuItem === 2) {
      openUrl(config.youtubeProfile);
    } else {
      setContactOpen(true);
    }
  }, [bottomMenuItem]);

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <Grid container sx={{ padding: 0 }}>
        <Grid item xs={12} md={5}>
          <Box>
            <Tabs value={current} onChange={(_: React.SyntheticEvent, item: TabRouterItems) => {
              setCurrentItem(item);
            }} centered>
              <Tab label="Collection" className={classes.menuTab} />
              <Tab label="Albums" className={classes.menuTab} />
              <Tab label="Information" className={classes.menuTab} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <div className={classes.logo}>
            <a
              href={`${config.protocol}://www.${config.domain}`}
              className={classes.title}
            >
              <img src={'/logo.png'} className={classes.logoImage} alt={'Etc.'}/>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} md={5} />
        <Grid item xs={12} md={12}>
          <div className={classes.search}>
            <Search />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}