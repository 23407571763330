import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import {
  Expand as ExpandIcon,
  VerticalAlignCenter as VerticalAlignCenterIcon,
  Lightbulb as LightbulbIcon
} from "@mui/icons-material";
import { compact, eq, size, values } from 'lodash';

import { SoundContext } from 'src/context/SoundContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '30px',
    top: 'calc(100% - 23px)',
    zIndex: 20,
  },
});

type Props = {};

export const Footer: React.FC<Props> = () => {
  const classes = useStyles();
  const { expandAll, list, isExpanded } = useContext(SoundContext);

  const isListExpanded = eq(
    size(values(list)),
    size(compact(values(isExpanded))),
  );

  const dialActionList = [
    {
      icon: isListExpanded
        ? <VerticalAlignCenterIcon />
        : <ExpandIcon />,
      name: isListExpanded ? 'Close sounds' : 'Show sounds',
      action: () => {
        expandAll(!isListExpanded);
      },
    },
  ];

  const [open, setOpen] = React.useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <Backdrop open={open} onClick={onClose} />
      <SpeedDial
        ariaLabel="User activity"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<LightbulbIcon />}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
      >
        {dialActionList.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>
    </div>
  );
}