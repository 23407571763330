import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { Container, CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { theme } from 'src/styles/theme';
import { ContainerCustom } from '../../shared/custom/container-custom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  }
});

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  children: any;
};

export const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <ContainerCustom>
          {children}
        </ContainerCustom>
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.any,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}