import React, { Context, createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { assign, compact, concat, get, isUndefined, join, lowerCase, map, merge, reduce } from 'lodash';

import { EMPTY_STRING, SPACE } from 'src/utils/common';
import { Sound, SOUND_LIST, Uid } from '../services/resources';

export const DEFAULT_SOUND_YEAR = 2023;

export type Expand = { [uid: string]: boolean };

type SoundContextValue = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  list: Sound[];
  search: string;
  setSearch: (search: string) => void;
  year: number;
  setYear: (year: number) => void;
  isExpanded: Expand,
  expand: (code: Uid) => void;
  setExpanded: (expand: Expand) => void;
  expandAll: (isOpen: boolean) => void;
};

export const SoundContext: Context<SoundContextValue> =
  createContext<SoundContextValue>({
    loading: false,
    setLoading: (loading: boolean) => void 0,
    list: [],
    search: EMPTY_STRING,
    setSearch: (search: string) => void 0,
    year: DEFAULT_SOUND_YEAR,
    setYear: (year: number) => void 0,
    isExpanded: {},
    expand: (code: Uid) => void 0,
    setExpanded: (expand: Expand) => void 0,
    expandAll: (isOpen: boolean) => void 0,
  });

export const SoundProvider: React.FC<any> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(EMPTY_STRING);
  const [list, setList] = useState<Sound[]>(SOUND_LIST);
  const [year, setYear] = useState<number>(DEFAULT_SOUND_YEAR);
  const [isExpanded, setExpanded] = useState<Expand>({});

  function expand(uid: Uid): void {
    setExpanded(assign({}, isExpanded, {
      [uid]: !get(isExpanded, uid, false),
    }));
  }

  function expandAll(isOpen: boolean): void {
    setExpanded(
      reduce(list, (result: Expand, sound: Sound) => {
          assign(result, {
            [sound.uid]: isOpen,
          });
          return result;
        },
        {}
      )
    );
  }

  useEffect(() => {
    setExpanded({});
    const soundList = map(SOUND_LIST, (sound: Sound, key: number) => {
      const contentValue = join(
        concat(
          get(sound, 'title', void 0),
          get(sound, 'code', void 0),
          get(sound, 'id', void 0),
        ),
        SPACE
      );
      const searchPosition = contentValue
        .search(new RegExp(`${lowerCase(search)}`, 'i'));
      return searchPosition === -1 ? void 0 : sound;
    });
    setList(compact(soundList));
  }, [search]);

  useEffect(() => {
    setList(SOUND_LIST);
  }, []);

  return (
    <SoundContext.Provider value={{
      loading,
      setLoading,
      list,
      search,
      year,
      setYear,
      setSearch,
      expand,
      isExpanded,
      setExpanded,
      expandAll,
    }}>
      {children}
    </SoundContext.Provider>
  );
};

SoundProvider.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.any,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
