import React, { Context, createContext, useState } from 'react';
import { noop } from 'lodash';
import { ModalContent } from '../components/shared/modal-content';
import { EMPTY_BLOCK } from '../utils/common';

type ModalContextValue = {
  open: boolean;
  handleOpen: (callback?: () => void) => void;
  handleClose: (callback?: () => void) => void,
  setBody: (body: React.ReactElement) => void;
};

export const ModalContext: Context<ModalContextValue> =
  createContext<ModalContextValue>({
    open: false,
    handleOpen: (callback?: () => void) => void 0,
    handleClose: (callback?: () => void) => void 0,
    setBody: (body: React.ReactElement) => void 0,
  });

export const ModalProvider: React.FC<any> = ({ children }) => {
 const [open, setOpen] = useState<boolean>(false);
 const [body, setBody] = useState<any>(EMPTY_BLOCK);

 const handleOpen = (callback: () => void = noop) => {
   setOpen(true);
   callback();
 }

  const handleClose = (callback: () => void = noop) => {
    setOpen(false);
    callback();
  }

  return (
    <ModalContext.Provider value={{
      open,
      handleOpen,
      handleClose,
      setBody,
    }}>
      <ModalContent
        open={open}
        handleClose={handleClose}
        body={body}
      />
      {children}
    </ModalContext.Provider>
  );
}