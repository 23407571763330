import React from 'react';
import { map } from 'lodash';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ALBUM_LIST, SoundAlbum } from 'src/services/resources/album.model';
import { openUrl } from '../../utils/window';
import { theme } from '../../styles/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: '115px',
    paddingLeft: '20px',
    paddingRight: '20px',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '155px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '145px',
    },
  },
  media: {
    cursor: 'pointer',
  },
  title: {
    cursor: 'pointer',
  },
  actionButton: {
    fontFamily: 'Arial !important',
  },
  actionButtonBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    paddingBottom: '0 !important',
  }
});

type Props = {};

export const AlbumSection: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        {map(ALBUM_LIST, ({ title, description, img, link }: SoundAlbum, key: number) => {
          return (
            <Grid item xs={12} md={6}>
              <Card >
                <CardMedia
                  component="img"
                  alt={title}
                  height="250"
                  image={img}
                  className={classes.media}
                  onClick={() => link.soundCloud ? openUrl(link.soundCloud) : void 0}
                />
                <CardContent className={classes.content}>
                  <Typography
                    gutterBottom
                    variant="h2"
                    component="div"
                    align={'center'}
                    className={classes.title}
                    onClick={() => link.soundCloud ? openUrl(link.soundCloud) : void 0}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.actionButtonBlock}>
                  <Button
                    size="medium"
                    className={classes.actionButton}
                    onClick={() => link.soundCloud ? openUrl(link.soundCloud) : void 0}
                  >
                    SoundCloud
                  </Button>
                  <Button
                    size="medium"
                    className={classes.actionButton}
                    onClick={() => link.apple ? openUrl(link.apple) : void 0}
                  >
                    Apple Music
                  </Button>
                  <Button
                    size="medium"
                    className={classes.actionButton}
                    onClick={() => link.spotify ? openUrl(link.spotify) : void 0}
                  >
                    Spotify
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}