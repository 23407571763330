import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  Tooltip,
  Typography
} from '@mui/material';
import { Check, Link, QrCodeScanner, Telegram } from '@mui/icons-material';
import QrCode from 'react-qr-code';

import { Maybe } from 'src/types';
import { AppleMusicSound, AUTHOR, ContentType, SpotifySound, TwitterSound, YouTubeSound } from 'src/services/resources';
import { theme } from 'src/styles/theme';
import { composeSoundLink, composeTwitterContent, utf8format } from 'src/utils/content';
import { copyUrl, openUrl } from 'src/utils/window';

import { SoundItem } from '../sound-item';
import classNames from 'classnames';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    overflowY: 'auto',
  },
  title: {
    paddingLeft: '70px',
  },
  stanzaList: {

  },
  stanza: {
    paddingBottom: '20px',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
  stanzaRow: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  epilogue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  shareTelegram: {
    marginTop: "-4px !important",
    marginLeft: "4px !important",
  },
  shareLink: {
    marginTop: '-4px !important',
  },
  divider: {
    maxWidth: '100%',
    marginLeft: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
    },
  },
  qrTitle: {
    alignSelf: 'center',
    paddingBottom: '5px !important',
  },
  qrTitleText: {
    fontSize: '20px',
    paddingTop: '10px',
  },
  qrCloseActions: {
    justifyContent: 'space-around !important',
    marginTop: '-10px',
  },
  qrClose: {
    fontSize: '12px',
    padding: '10px',
  },
  author: {
    color: '#002e30',
    textAlign: 'center',
    paddingBottom: '20px',
  },
  qr: {},
  tweetButton: {
    paddingTop: '10px',
  },
  hidden: {
    display: 'none',
  }
});

type Props = {
  type: ContentType | ContentType[],
  title: string;
  secretToken: string;
  date: Date;
  code: string;
  id?: Maybe<string>;
  appleSound?: Maybe<AppleMusicSound>;
  spotifySound?: Maybe<SpotifySound>;
  youtubeSound?: Maybe<YouTubeSound>;
  twitterSound?: Maybe<TwitterSound>;
  twitterLoading?: Maybe<boolean>;
};

export const SoundContentSection: React.FC<Props> = ({
  type,
  secretToken,
  id,
  title,
  date,
  code,
  appleSound,
  spotifySound,
  youtubeSound,
  twitterSound,
  twitterLoading,
}) => {
  const classes = useStyles();

  const [copyOpen, setCopyOpen] = useState<boolean>(false);
  const [qrOpen, setQrOpen] = useState<boolean>(false);
  const [isTweetLoading, setTweetLoading] = useState<boolean>(true);

  const onQrClick = () => {
    setQrOpen(true);
  };

  const onQrClose = () => {
    setQrOpen(false);
  };

  // @note: tweet loading, displaying handler
  setTimeout(() => {
    setTweetLoading(false);
  }, 2000);

  return (
    <div className={classes.root}>
      <Dialog
        open={qrOpen}
        keepMounted
        onClose={onQrClose}
        aria-describedby="qr-code-dialog"
      >
        <DialogTitle className={classes.qrTitle}>
          <Typography
            variant={'h1'}
            className={classes.qrTitleText}
          >
            {`${title}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="author">
            <Typography variant={'h3'} className={classes.author}>
              Etc.
            </Typography>
          </DialogContentText>
          <div className={classes.qr}>
            <QrCode
              size={256}
              value={composeSoundLink(code)}
              viewBox={`0 0 256 256`}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.qrCloseActions}>
          <IconButton
            color={'primary'}
            onClick={onQrClose}
          >
            <Check fontSize="large" />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Grid
        container
        className={classes.container}
      >
        <Grid item xs={12}>
          <Grid container className={classes.stanzaList}>
            <SoundItem
              type={type}
              secretToken={secretToken}
              id={id}
              title={title}
              code={code}
              appleSound={appleSound}
              spotifySound={spotifySound}
              youtubeSound={youtubeSound}
              twitterSound={twitterSound}
              twitterLoading={twitterLoading}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.epilogue}>
            <div>
              <Typography variant={'body1'} sx={{ color: 'text.secondary' }}>
                {AUTHOR}, {date.getFullYear()}
              </Typography>
            </div>
            <div>
              <Tooltip
                title={"Share"}
                arrow
              >
                <IconButton
                  className={classes.shareTelegram}
                  onClick={() => {
                    openUrl(`https://t.me/share/url?url=${encodeURIComponent(composeSoundLink(code))}&text=${`${title}${encodeURIComponent('\n')}Etc.`}`);
                  }}
                  aria-label={title}
                >
                  <Telegram />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={"QR"}
                arrow
              >
                <IconButton
                  className={classes.shareTelegram}
                  onClick={onQrClick}
                  aria-label={title}
                >
                  <QrCodeScanner />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={copyOpen}
                ContentProps={{
                  sx: {
                    display: 'block',
                    textAlign: "center"
                  }
                }}
                TransitionComponent={(props: any) =>
                  <Slide {...props} direction="down" />}
                message={'Sound link is copied...'}
                key={code}
              />
              <Tooltip
                title={copyOpen ? "Copied" : "Sound link"}
                arrow
              >
                <IconButton
                  className={classes.shareLink}
                  onClick={() => {
                    copyUrl(composeSoundLink(code));
                    setCopyOpen(true);
                    setTimeout(() => {
                      setCopyOpen(false);
                    }, 2000);
                  }}
                  aria-label={'copy'}
                >
                  <Link />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={copyOpen ? "Twitted" : "Twitter Sound"}
                arrow
              >
                <IconButton
                  className={classes.shareLink}
                  onClick={() => {
                    openUrl(`https://twitter.com/intent/tweet?text=${composeTwitterContent()}&url=${utf8format(composeSoundLink(code))}&hashtags=etc,life,science,electronic,livemusic&via=etcsci`);
                  }}
                  aria-label={'tweet'}
                >
                  <img
                    src={'/img/twitter.png'}
                    height={20}
                    width={20}
                    alt={`Twitter ${title}`}
                  />
                </IconButton>
              </Tooltip>

              {/*<div className={classNames([classes.tweetButton, isTweetLoading ? classes.hidden : void 0])}>*/}
              {/*  <a*/}
              {/*    className="twitter-share-button"*/}
              {/*    href="https://twitter.com/intent/tweet?text=Hello%20world"*/}
              {/*  >*/}
              {/*    Tweet*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}