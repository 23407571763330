import { Maybe } from '../../types';

export type SoundAlbum = {
  title: string;
  description: string;
  img: string;
  link: SoundAlbumLink;
};

export type SoundAlbumLink = {
  soundCloud?: Maybe<string>;
  apple?: Maybe<string>;
  spotify?: Maybe<string>;
};

export const ALBUM_LIST: SoundAlbum[] = [
  {
    title: 'Neutral Life',
    description: '',
    img: 'https://i.scdn.co/image/ab67616d0000b2739f22986913c5d97440245498',
    link: {
      apple: 'https://music.apple.com/us/album/neutral-life/1661473865',
      spotify: 'https://open.spotify.com/album/4BriMErCTcOVs6s48V3xxl',
      soundCloud: 'https://soundcloud.com/raman-marozau/sets/neutral-life',
    }
  },
  {
    title: 'Usual Neural',
    description: '',
    img: 'https://i.scdn.co/image/ab67616d0000b2739935fa3aa7b7a7bc08245d25',
    link: {
      apple: 'https://music.apple.com/us/album/1677206960',
      spotify: 'https://open.spotify.com/album/6td5ftWoFgMXJGMutvwd8X',
      soundCloud: 'https://soundcloud.com/raman-marozau/sets/usual-neural',
    }
  }
]