import React, { useContext } from 'react';
import Tab  from '@mui/material/Tab';
import { TabPanelUnstyled } from '@mui/base';
import { TabRouterItems } from '../../../services/resources/app.model';
import { SoundSection } from '../../sound';
import { TabRouterContext } from '../../../context/TabRouterContext';
import { AlbumSection } from '../../album';
import { InformationSection } from '../../information';
import { TabPanel } from '../../shared/tab-panel';

type Props = {};

export const TabRouter: React.FC<Props> = () => {
  const { current } = useContext(TabRouterContext);

  return (
    <>
      <TabPanel value={current} index={TabRouterItems.Collection}>
        <SoundSection />
      </TabPanel>
      <TabPanel value={current} index={TabRouterItems.Albums}>
        <AlbumSection />
      </TabPanel>
      <TabPanel value={current} index={TabRouterItems.Information}>
        <InformationSection />
      </TabPanel>
    </>
  );
}