import React, { Context, createContext, useState } from 'react';

import { TabRouterItems } from 'src/services/resources/app.model';

type TabRouterContextValue = {
  current: TabRouterItems;
  setCurrentItem: (item: TabRouterItems) => void;
}

export const TabRouterContext: Context<TabRouterContextValue> =
  createContext<TabRouterContextValue>({
    current:  TabRouterItems.Collection,
    setCurrentItem: (item: TabRouterItems) => void 0,
  });

export const TabRouterProvider: React.FC<any> = ({ children }) => {
  const [current, setCurrent] = useState<TabRouterItems>(TabRouterItems.Collection);

  const setCurrentItem = (item: TabRouterItems) => {
    setCurrent(item);
  }

  return (
    <TabRouterContext.Provider value={{
      current,
      setCurrentItem,
    }}>
      {children}
    </TabRouterContext.Provider>
  )
}