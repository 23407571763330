import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link, Typography } from '@mui/material';
import { theme } from '../../styles/theme';
import { config } from '../../config';
import { openUrl } from '../../utils/window';
import { Twitter } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: '115px',
    paddingLeft: '20px',
    paddingRight: '20px',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '155px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '145px',
    },
  },
  contactLink: {
    cursor: 'pointer',
  },
  twitterIcon: {
    marginTop: '2px',
  },
  contact: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }
});

type Props = {};

export const InformationSection: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'body1'} align={'center'}>
        <p>Etc. is an interpretation of the world existing around. The sound and the melodic is alive regarding natural existing systematisation. The sound is available in some time you started the listen to collect the dependencies of tones. Listening from the random entry point should give a chance to compose the minimum further evolution syntax in time. Try to avoid the melody cleaning just exist with the music endeavour. The sound melody can not be repeated. It’s alive.</p>
        <p>Thanks for the listening!</p>
        <p>Etc.</p>
        <div className={classes.contact}>
          <Twitter className={classes.twitterIcon}/>
          <Link
            className={classes.contactLink}
            onClick={() => openUrl(`https://twitter.com/${config.twitter}`)}
          >
            @{config.twitter}
          </Link>
        </div>
      </Typography>
    </div>
  );
}